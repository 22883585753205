<template>
  <section
    class="free-shipping-coupon free-shipping-childType freeShipping-type"
    :class="{
      'free-type-sticky': isSticky,
      'free-type-showCountdown': !showCountdown
    }"
    @click="handleCouponClick"
  > 
    <template v-if="isNewSticky">
      <NewUserItem
        :item="item"
        :meta-data="metaData"
        :title-name="title"
        :is-one-comp="isOneComp"
        icon-name="sui_icon_policy_shipping_16px"
        :main-title-color="titleColor"
      />
    </template>
    <template v-else>
      <!-- 非吸顶&&非完成-右下角icon -->
      <div
        v-if="!isSticky"
        class="freeshipping-rb not-fsp-element"
      >
        <Icon
          name="sui_icon_policy_shipping_16px" 
          size="16px" 
          :color="iconColor"
        />
      </div>
      <div 
        class="free-shipping-coupon__title"
        :class="{
          'full-width': !showCountdown && isSticky
        }"
      >
        <!-- 吸顶满足免邮 -->
        <template v-if="isFullActivity && isSticky">
          <Icon
            name="sui_icon_selected_16px"
            :color="freeThresholdIconColor"
            size="16px"
            class="free-shipping-coupon__title-icon color-success not-fsp-element"
            :style="{height: '16px'}"
          />
        </template>
        <div 
          v-if="!isFullActivity && isSticky" 
          class="sticky-single__icon-wrap"
        >
          <div class="sticky-single-flex not-fsp-element">
            <Icon
              name="sui_icon_policy_shipping_16px"
              :color="mainTitleColor"
              size="16px"
              class="free-shipping-coupon__title-icon"
              :style="{height: '16px'}"
            />
          </div>
        </div>
        <!-- 文案 -->
        <p 
          class="free-shipping-coupon__title-txt"
          :class="{ 
            'is-sticky': isSticky,
            'had-progress-bar': isSticky && !isFullActivity && showProgress
          }"
        >
          <!-- 吸顶 -->
          <template v-if="isSticky">
            <!-- 吸顶文案 -->
            <span
              :style="{color: subTitleColor, lineHeight: '13px', fontWeight: 400}"
            >
              {{ topTitle }}
            </span>
          </template>
          <!-- 不吸顶 -->
          <template
            v-else
          >
            <span class="main-subtitle" :style="{color: mainTitleColor, fontWeight: 'bold'}">{{ title }}</span>
          </template>
        </p>
      </div>
      <!-- 倒计时 -->
      <section
        v-if="showCountdown"
        class="free-shipping-coupon__sub-info countdown"
        :class="{ 'is-sticky': isSticky, 'free-shipping-coupon__is-new-style': metaData?.isNewUserStyle == '1' }"
        :style="{color: subTitleColor, fontSize: '11px'}"
      >
        <span
          :style="countdownTextStyle()"
        > {{ language.SHEIN_KEY_PWA_25713 }} </span>
        <div
          class="box cccCountTimeBox"
          style="direction: ltr"
          tabindex="0"
          role="text"
        >
          <BaseCountdown
            v-if="countTimer"
            :count-timer="countTimer"
            :itemStyle="{
              color:
                metaData?.isNewUserStyle == '1'
                  ? metaData?.couponBgColorStart
                  : metaData.bgType == 2 && metaData?.bgImage?.src
                  ? '#fff'
                  : bgColor,
              background:
                metaData?.isNewUserStyle == '1'
                  ? hexToRGBA(subTitleColor, 0.8)
                  : metaData.bgType == 2 && metaData?.bgImage?.src
                  ? '#000000cc'
                  : hexToRGBA(textColor, 0.8),
            }"
          />
        </div>
      </section>
      <p 
        v-else-if="!isSticky"
        class="free-shipping-coupon__sub-info"
        :style="{color: subTitleColor, fontSize: '11px' }"
      >
        <!-- 购物车服务免邮活动的门槛值； -->
        {{ subTitleReal }}
      </p>
      <!-- 进度条 -->
      <section class="bottomProgressWrap">
        <p 
          v-if="!isFullActivity && showProgress"
          class="free-shipping-coupon__progress"
          :class="{
            'free-shipping-coupon__hasCountdown': showCountdown
          }"
          :style="{
            backgroundColor: processBgColor,
          }"
        >
          <span
            class="free-shipping-coupon__progress-bar"
            :style="{
              width: `${progressWidth}%`,
              backgroundColor: processColor,
            }"
          ></span>
        </p>
      </section>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import BaseCountdown from '../atomic-component/BaseCountdown'
import { CountDown, template } from '@shein/common-function'
import { getServerTime } from 'public/src/pages/components/ccc/common/utils.js'
import { Icon } from '@shein-aidc/icon-vue3'
import NewUserItem from '../atomic-component/NewUserItem'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
//mixin
import freeshippingMixin from '../mixin'
// 免邮信息（组件）
export default defineComponent({
  name: 'FreeShipping',
  components: {
    Icon,
    BaseCountdown,
    NewUserItem
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon'],
  inheritAttrs: true,
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    newIsSticky: {
      type: Boolean,
      default: false
    },
    getSelfCoupon: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    siteFreeMallInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    freeShippingInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    isNewSticky: {
      type: Boolean,
      default: false
    },
    titleColor: {
      type: String,
      default: '#222222'
    },
    isOneComp: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const { 
      showCountdown = 'false',
      showProgress = 'false',
      isFullActivity = '0', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      couponRules = [],
    } = this.item
    return {
      showCountdown: showCountdown == 'true', // 是否展示倒计时
      showProgress: showProgress == 'true', // 是否展示进度条
      countTimer: new CountDown(),
      useCoupon: false, // 是否使用免邮券 // TODO: 待bff 确认
      progressWidth: 0,
      isFullActivity: isFullActivity == '1', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      couponRules,
      diffValue: couponRules?.[0]?.diffPrice || {},
      thresholdValue: couponRules?.[0]?.thresholdPrice || {},
      freeMallCouponInfo: [],
      
    }
  },
  computed: {
    topTitle() {
      // {diff} 取 diffPrice、{1} 取 priceSymbol
      return this.isFullActivity ? this.topTitleFulfil : template(this.diffValue?.amountWithSymbol || '0',  this.topTitleNotFulfil?.replace('{diff}', '{0}')) 
    },
    subTitleReal() {
      return template(this.thresholdValue?.amountWithSymbol, this.subtitle) 
    },
    HomePageFontStyleNew() {
      return this.context?.abtInfoObj?.HomePageFontStyle == 'new'
    },
    isShowShippingCoupon() {
      return this.item?.freeShippingInfo?.freeShippingType == 'coupon'
    },
    freeThresholdIconColor() { // 达到免邮门槛的icon颜色（对勾）
      const color = this.metaData?.isNewUserStyle === '1' ? (this.metaData?.newUserIconColor || '#169E00') : (this.metaData?.iconColor || '#198055')
      return color
    },
  },
  watch: {
    siteFreeMallInfo: {
      deep: true,
      handler() {
        if(typeof window == 'undefined') return
        this.updateFree()
      }
    },
    freeShippingInfo: {
      deep: true,
      handler() {
        if(typeof window == 'undefined') return
        this.updateFreeShippingCoupon()
      }
    },
  },
  mounted() {
    this.showCountdown && this.initCountDown()
    this.getProgressWidth() 
    this.updateFreeShippingCoupon()
    this.updateFree()
  },
  activated() {
    this.showCountdown && this.initCountDown()
  },
  deactivated() {
    this.countTimer.clear()
  },
  methods: {
    template,
    // 获取免邮券信息
    async updateFreeShippingCoupon() {
      const { freeShippingInfo } = this.item
      const { freeShippingType = 'activity', freeShippingCoupons = [] } = freeShippingInfo || {}
      if (freeShippingType == 'coupon' && freeShippingCoupons.length) {
        const coupon = freeShippingCoupons[0]
        const couponDetail =  this.freeShippingInfo?.couponCmpInfos?.[0] || {}
        // 不需要展示领券状态。券包内会出现 COLLCECT 按钮
        delete couponDetail?.coupon_status
        // 券详情时间只跟券相关，使用首页接口返回的有效期
        delete couponDetail?.start_date
        delete couponDetail?.end_date
        if (coupon?.endTime) { // 首页接口有效期有值
          couponDetail.endDate = coupon.endTime
        }
        if (coupon?.startTime) { // 首页接口有效期有值
          couponDetail.startDate = coupon.startTime
        }
        this.freeMallCouponInfo = [{
          ...coupon,
          ...couponDetail
        }]
        // 处理免邮券信息
        if(this.freeMallCouponInfo.length > 0) {
          const freeMallCoupon = this.freeMallCouponInfo[0]
          const { couponRules = [] } = freeMallCoupon
          const { diffPrice, thresholdPrice } = couponRules[0] || {}
          this.diffValue = diffPrice
          this.thresholdValue = thresholdPrice
          this.useCoupon = true
          if (this.showProgress) {
            this.progressWidth = typeof diffPrice?.amount == 'undefined' ? 5 : Number(((1 - diffPrice?.amount / thresholdPrice?.amount) * 100).toFixed(1))
          }
        }
      }
    },
    // 倒计时结束处理的函数
    handelTimeEnd() {
      const nowTime = Date.now()
      const { startTime, endTime, countdownType } = this.item
      this.showCountdown = countdownType == 1 ? (nowTime < endTime && nowTime > startTime) : false
    },
    // 计算进度条的宽度
    getProgressWidth() {
      if (this.showProgress) {
        this.progressWidth = typeof this.diffValue?.amount == 'undefined' ? 5 : Number(((1 - this.diffValue?.amount / this.thresholdValue?.amount) * 100).toFixed(1))
      }
    },
    countdownTextStyle() {
      return {
        display: 'inline-block',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        verticalAlign: 'middle',
        marginRight: '1px',
        fontSize: this.HomePageFontStyleNew ? '11px' : '10px', 
      }
    },
    getCountTime(curTime) {
      // startTime, endTime, 总共活动市场
      const { startTime, endTime, countdownType } = this.item
      const leftTime = this.siteFreeMallInfo?.cart_total_free?.left_time
      const realEndTime = leftTime || endTime
      // 1. 异常时间段
      if (curTime < startTime || realEndTime < curTime) {
        this.handelTimeEnd()
        return 0
      }
      const hours_24 = 24 * 3600
      const timeLag = (realEndTime - curTime) / 1000 // 结束时间 - 当前时间 （单位s）
      if (countdownType == 1) {
        if(timeLag >= hours_24) { // 结束时间 - 当前时间 > 24h
          const endOfDay = new Date().setHours(24, 0, 0, 0) // 获取今天 24:00:00 的时间
          const diff = (endOfDay - curTime) / 1000 // 当前时间距离今天24:00:00还有多久
          return diff
        } else {
          return timeLag
        }
      } else {
        return timeLag
      }
      // countdownType 1- 循环倒计时、2- 非循环倒计时
      // if (countdownType == 1) {
      //   const loopTime = (countdownSize || 24) * 3600  // 每循环周期， 单位s
      //   const overTime = (curTime - startTime) / 1000  // 已经消耗的时间，单位s
      //   const hasBeenLostTime = overTime % loopTime // 当前时间处在的循环的时间已经 开始了多少 单位s
      //   const lastBeginTime = (curTime / 1000) - hasBeenLostTime // 当前时间处在的循环的开始时间 单位s
      //   const lastEndTime = lastBeginTime + loopTime > (endTime / 1000) ? (endTime / 1000) : lastBeginTime + loopTime //  单位s
      //   return lastEndTime - curTime / 1000
      // }
      // return endTime / 1000 - curTime / 1000
    },
    async initCountDown() {
      const serverTime = this.isSticky ? Date.now() : await getServerTime()
      const countTime = this.getCountTime(serverTime)

      this.countTimer.start({
        seconds: countTime,
        dayMode: true,
        endFunc: this.handelTimeEnd
      })
    },
    // 免邮信息更新
    updateFree() {
      this.showCountdown && this.initCountDown()
      if (!this.siteFreeMallInfo || !this.siteFreeMallInfo?.free_threshold) return
      let freeThreshold = this.siteFreeMallInfo?.free_threshold   // 免邮门槛数据
      let diffFreeAmount = this.siteFreeMallInfo?.diff_free_amount   // 免邮差值
      const isFreeShipping = this.siteFreeMallInfo?.is_free_shipping   // 是否满足免邮
      const freeShippingInfo = this.siteFreeMallInfo?.freeShippingInfo // 是否是免邮券
      // 如果是免邮券
      if(this.isShowShippingCoupon) {
        const coupon = freeShippingInfo?.freeShippingCoupons[0]
        diffFreeAmount = coupon?.couponRules[0]?.diffPrice
        freeThreshold = coupon?.couponRules[0]?.thresholdPrice
      }
      const diffAmount = diffFreeAmount // 差值免邮

      this.thresholdValue = freeThreshold // 免邮金额
      this.diffValue = typeof diffAmount == 'undefined' ? this.thresholdValue : diffAmount 
      this.isFullActivity = isFreeShipping == 1

      if (this.showProgress) {
        this.progressWidth = typeof diffFreeAmount?.amount == 'undefined' ? 5 : Number(((1 - diffFreeAmount.amount / freeThreshold.amount) * 100).toFixed(1))
      }
    },
    handleCouponClick(item = {}) {
      this.$emit('clickCoupon', {
        item: {
          ...this.item,
          ...item,
          threshold: this.isFullActivity,
          diffValue: this.diffValue?.amountWithSymbol,
          progressWidth: this.progressWidth,
          countTimer: this.countTimer,
          showCountdown: this.showCountdown,
          showProgress: this.showProgress,
          useCoupon: this.useCoupon,
          freeMallCouponInfo: this.freeMallCouponInfo,
          isShowShippingCoupon: this.isShowShippingCoupon,
        },
        index: this.index,
      })
    },
    hexToRGBA,
  },
})
</script>

<style lang="less" scoped>
// 公共混入
.text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-ellipsis(@line: 2) {
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 基础变量
@base-font-size: 11px;
@small-font-size: 10px;
@base-line-height: 13px;
@progress-height: 1.5px;

.countdown {
  display: flex;
  z-index: 2;
  align-items: center;
  .box {
    display: inline-flex;
    vertical-align: middle;
  }
}

.free-shipping-coupon {
  padding: 0 6px 0 8px;
  &__title {
    &.full-width {
      max-width: 100% !important;
      flex: 1;
    }
  }
  
  &__title-txt {
    width: 100%;
    
    &.is-sticky {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: @base-font-size;
      line-height: @base-line-height;
      flex: 1;
      
      > span {
        .multi-ellipsis(2);
        line-height: @base-line-height;
      }
    }
    
    .main-subtitle {
      display: block;
      margin-bottom: -3px;
      height: 16px;
      line-height: 16px;
      .text-ellipsis();
    }
  }
  
  &__progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 20px);
    height: @progress-height;
    border-radius: @progress-height;
    overflow: hidden;
    
    &.free-shipping-coupon__hasCountdown {
      width: 100%;
    }
  }
  
  &__progress-bar {
    position: absolute;
    height: 100%;
    background-color: #222222;
    transition: width 0.3s ease;
  }
}

.free-shipping-coupon__sub-info:not(.is-sticky) {
  max-width: calc(100% - 16px);
  font-size: @small-font-size;
  align-items: center;
}

.free-shipping-coupon__is-new-style {
  max-width: 100%;
}

.free-type-sticky {
  line-height: 72/75rem;
  
  .free-shipping-coupon__sub-info {
    padding-top: 0;
  }
  
  .bottomProgressWrap {
    width: calc(100% - 20px);
    margin-left: 0;
  }
  
  .cccCountTimeBox {
    margin-right: -2px;
  }
  
  &.free-type-showCountdown .bottomProgressWrap {
    width: 100%;
  }
}

.bottomProgressWrap {
  width: calc(100% - 20px);
  position: absolute;
  bottom: 3px;
}

.new-user-coupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
