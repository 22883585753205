import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

export default {
  props: {
    propData: {
      type: Object,
      default: () => {}
    },
    sceneData: {
      type: Object,
      default: () => {}
    },
    context: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    metaData(){
      return this.propData?.props?.metaData || {}
    },
    firstItem() {
      return this.propData?.props?.items?.[0] ?? {}
    },
    bgColor() {
      return this.metaData.showCustomBg ? (this.metaData.bgColor || '#FCF5E6') :  
        this.firstItem?.showCustomBg ? this.firstItem?.bgColor || '#FCF5E6' : '#FCF5E6'
    },
    textColor() {
      if (this.metaData.isNewUserStyle == '1') {
        return this.metaData?.freeShippingColor || '#5C0B0B'
      }
      return this.metaData.showCustomBg ? (this.metaData.textColor || '#5C0B0B') :  
        this.firstItem?.showCustomBg ? this.firstItem?.textColor || '#5C0B0B' : '#5C0B0B'
    },
    iconColor() {
      if(this.metaData.isNewUserStyle == '1') {
        return hexToRGBA(this.textColor, 1)
      }
      return hexToRGBA(this.textColor, 0.2)
    },
    mainTitleColor() {
      return this.textColor
    },
    subTitleColor() {
      if (this.metaData.isNewUserStyle == '1') {
        return this.metaData.subTitleColor || '#5C0B0B'
      }
      return hexToRGBA(this.textColor, 0.8)
    },
    processBgColor() {
      if (this.metaData.isNewUserStyle == '1') {
        return hexToRGBA(this.textColor, 0.2)
      }
      return hexToRGBA(this.textColor, 0.3)
    },
    processColor() {
      return hexToRGBA(this.textColor, 0.8)
    },
  },
  mounted(){
    // console.log('this.propData', this.$attrs?.propData)
  },
  methods: {}
}
