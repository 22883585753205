<template>
  <section 
    class="free-shipping-coupon free-shipping-childType self-coupon-type self-coupon-type-4or5"
    :class="{
      'self-coupon-sticky': isSticky,
      'onlyTitle': !item.subtitle
    }"
    @click="handleCouponClick"
  >
    <template v-if="isNewSticky">
      <NewUserItem
        :item="item"
        :meta-data="metaData"
        :title-name="item.title"
        :is-one-comp="isOneComp"
        :icon-name="iconMap[item.iconStyle?.iconId]"
        :main-title-color="titleColor"
      />
    </template>
    <template v-else>
      <div
        class="free-shipping-coupon__title"
      >
        <div
          class="free-shipping-coupon__title-icon not-fsp-element" 
          :class="{
            'free-shipping-coupon__iconrb': !isSticky,
          }"
          style="width:auto"
        >
          <Icon
            v-if="item.iconType == 2 && iconMap[item.iconStyle?.iconId]"
            :name="iconMap[item.iconStyle?.iconId]"
            :color="isSticky ? mainTitleColor : iconColor"
            size="16px"
          />
          <BaseImg
            v-else
            :placeholder="{
              width: item.image.width,
              height: item.image.height,
            }"
            :ratio="item.image.ratio"
            img-design-width="26"
            :img-src="item.image.src"
            :alt="item.hrefTitle"
            :first-screen="true"
            :style="{'height': metaData?.isNewUserStyle == '1' ? '13px' : 'auto'}"
          />
        </div>
        <div
          v-if="!isSticky"
          :style="{color: mainTitleColor, fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis'}"
        >
          {{ item.title }}
        </div>
        <div
          v-else
          class="stickyTitle"
          :style="{color: subTitleColor}"
        >
          <b>{{ item.title }}</b>
          <i v-if="item.subtitle">{{ `&nbsp;&nbsp;&nbsp;${item.subtitle}` }}</i>
        </div>
      </div>
      <p 
        v-if="!isSticky"
        class="free-shipping-coupon__sub-info"
        :style="{color: subTitleColor, fontSize:'11px'}"
      >
        {{ item.subtitle }}
      </p>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import NewUserItem from '../atomic-component/NewUserItem'

const iconMap = {
  baoyou: 'sui_icon_policy_shipping_16px',
  zhekouquan: 'sui_icon_policy_discount_16px',
  youhuiquan: 'sui_icon_policy_coupon_16px',
  tuihuo: 'sui_icon_policy_returns_16px',
  baojia: 'sui_icon_policy_insured_16px',
  zhengce: 'sui_icon_policy_pdf_16px',
  qiandao: 'sui_icon_policy_checked_16px',
  jinbi: 'sui_icon_policy_points_16px',
  cuxiao: 'sui_icon_policy_activity_16px',
  shangou: 'sui_icon_policy_flashsale_16px',
  lipinka: 'sui_icon_policy_gift_16px',
  mianfeishiyong: 'sui_icon_policy_free_16px',
  renyuanzhaoping: 'sui_icon_policy_recruitment_16px'
}
//mixin
import freeshippingMixin from '../mixin'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'

export default defineComponent({
  name: 'SelfCoupon',
  components: {
    Icon,
    BaseImg,
    NewUserItem
  },
  mixins: [mixin, freeshippingMixin],
  inheritAttrs: true,
  emits: ['clickCoupon'],
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isNewSticky: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    titleColor: {
      type: String,
      default: ''
    },
    isOneComp: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      iconMap,
    }
  },
  mounted() {
    this.handleSlslog()
  },
  methods: {
    handleCouponClick() {
      this.$emit('clickCoupon', {
        item: this.item,
        index: this.index,
      })
    },
    handleSlslog() {
      if(!this.item?.title) {
        this.hideCompSls({
          logLevel: 3,
          tag: 'web_client_home_error_total',
          message: 'CCCDataError',
          reason: 'titleEmptyError',
        })
      }
      if(!this.item?.subtitle) {
        this.hideCompSls({
          logLevel: 3,
          tag: 'web_client_home_error_total',
          message: 'CCCDataError',
          reason: 'subTitleEmptyError',
        })
      }
    },
  },
})
</script>

<style lang="less" scoped>
/* stylelint-disable */
.self-coupon-type {
  padding: 0 6px 0 8px;
  
  .free-shipping-coupon__title {
    line-height: 18px;
    padding-bottom: 1px;
    display: flex;
    font-size: 13px;
  }
  .free-shipping-coupon__title-icon {
    padding-right: 8px;
    line-height: inherit !important;
  }
  .free-shipping-coupon__iconrb {
    position: absolute;
    padding-right: 0;
    height: 16px;
    bottom: 5px;
    right: 6px;
    z-index: 1;
    span {
      margin-bottom: 4px;
    }
  }
  .free-shipping-coupon__sub-info:not(.is-sticky) {
    max-width: calc(100% - 16px);
    font-size: 10px;
  }
}
// 吸顶
.free-shipping.is-fixed .self-coupon-sticky {
  .free-shipping-coupon__title {
    max-width: 100%;
    line-height: 15px;
    .stickyTitle{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
    }
  }
}

</style>
