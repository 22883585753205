<template>
  <div :style="containerStyle">
    <!-- 吸顶无券新客样式 -->
    <NewUserSticky
      v-if="isSticky && isNewUserSticky(showItems)"
      :get-analysis-data="getAnalysisData"
      :show-items="showItems"
      :only-single-style-page="onlySingleStylePage"
      v-bind="compAttrs"
    />
    <template
      v-for="(item, index) in items"
      v-else
    >
      <!-- 新客样式有券 -->
      <NewUserCoupon
        v-if="isHasNewUserCoupon(item)"
        :key="index"
        v-bind="compAttrs"
        :style="getDynamicStyle(true)"
        :get-analysis-data="getAnalysisData"
        :index="0"
        :item="item"
        :is-sticky="isSticky"
      />
      <FreeShipping
        v-if="item.type == 1"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        v-bind="compAttrs"
        :style="getDynamicStyle()"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        :is-show-shipping-coupon="isShowShippingCoupon"
        :get-self-coupon="getSelfCoupon"
      />
      <CollectOrder
        v-if="item.type == 2"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        v-bind="compAttrs"
        :style="getDynamicStyle()"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
      />
      <ReturnCoupon
        v-if="item.type == 3"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        v-bind="compAttrs"
        :style="getDynamicStyle()"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
      />
      <SelfCoupon
        v-if="item.type == 4 || item.type == 5"
        :key="`${item.type}-${index}`"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        v-bind="compAttrs"
        :style="getDynamicStyle()"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
      />
      <CouponCountdown
        v-if="item.type == 6"
        :key="item.type"
        ref="CouponCountdownSlide"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        v-bind="compAttrs"
        :style="getDynamicStyle()"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
      />
      <FreeShippingCoupon
        v-if="item.markFrame == '30'"
        :key="`${item.type}-${index}`"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :index="index"
        :item="item"
        v-bind="compAttrs"
      />
    </template>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import FreeShipping from './frame-component/FreeShipping.vue'
import ReturnCoupon from './frame-component/ReturnCoupon.vue'
import CollectOrder from './frame-component/CollectOrder.vue'
import SelfCoupon from './frame-component/SelfCoupon.vue'
import CouponCountdown from './frame-component/CouponCountdown.vue'
import NewUserCoupon from './frame-component/NewUserCoupon.vue'
import NewUserSticky from './frame-component/NewUserSticky.vue'
import FreeShippingCoupon from './frame-component/FreeShippingCoupon.vue'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

export default defineComponent({
  name: 'FreeShippingList',
  directives: {
    expose,
    tap,
  },
  components: {
    FreeShipping,
    ReturnCoupon,
    CollectOrder,
    SelfCoupon,
    CouponCountdown,
    NewUserCoupon,
    NewUserSticky,
    FreeShippingCoupon
    // Tips: 异步引入水合不一致报错导致整个组件空白 -> 改成惰性激活会出现一个闪动问题（体验不好）-> 暂时不做异步引入（后续优化，需要找到哪个数据变动导致的客户端水合不一致）
    // 可能的原因：
    // 1. 异步组件加载时，数据已经变动（传给组件的属性，特别是 $attrs ），导致客户端水合不一致
    // 2. 组件内部不合理的操作，如直接操作删除 props 中的属性，因为是个引用类型的数据，修改会改变传入的对象，而当前 props 的属性是多个组件共享的，比如 metaData，propData，items 等
    // FreeShipping: defineAsyncComponent(() => import(/* webpackChunkName: "free-shipping-comp" */'./frame-component/FreeShipping.vue')),
    // ReturnCoupon: defineAsyncComponent(() => import(/* webpackChunkName: "return-coupon-comp" */'./frame-component/ReturnCoupon.vue')),
    // CollectOrder: defineAsyncComponent(() => import(/* webpackChunkName: "collect-order-comp" */'./frame-component/CollectOrder.vue')),
    // SelfCoupon: defineAsyncComponent(() => import(/* webpackChunkName: "self-coupon-comp" */'./frame-component/SelfCoupon.vue')),
    // CouponCountdown: defineAsyncComponent(() => import(/* webpackChunkName: "coupon-countdown-comp" */'./frame-component/CouponCountdown.vue')),
    // NewUserCoupon: defineAsyncComponent(() => import(/* webpackChunkName: "new-user-coupon-comp" */'./frame-component/NewUserCoupon.vue')),
    // NewUserSticky: defineAsyncComponent(() => import(/* webpackChunkName: "new-user-sticky-comp" */'./frame-component/NewUserSticky.vue')),
    // FreeShippingCoupon: defineAsyncComponent(() => import(/* webpackChunkName: "free-shipping-coupon-comp" */'./frame-component/FreeShippingCoupon.vue')),
  },
  inheritAttrs: true,
  emits: ['getContainerStyle'],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    getAnalysisData: {
      type: Function,
      default: () => {}
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    metaData: {
      type: Object,
      default: () => {}
    },
    showItems: {
      type: Array,
      default() {
        return []
      }
    },
    // propData: {
    //   type: Object,
    //   default: () => {}
    // },
    isNewUserStyle: {
      type: Boolean,
      default: false
    },
    isStickyNewUser: {
      type: Boolean,
      default: false
    },
    getSelfCoupon: {
      type: Object,
      default: () => {}
    },
    isShowShippingCoupon: {
      type: Boolean,
      default: false
    },
    onlySingleStylePage: {
      type: Boolean,
      default: false
    },
    customComponentName: {
      type: String,
      default: ''
    },
    pageFrom: {
      type: String,
      default: ''
    },
    context: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      containerStyle: {
        width: '100%',
        'backgroundColor': ''
      }
    }
  },
  computed: {
    compAttrs() {
      return useMapAttrs(this.$attrs)
    }
  },
  methods: {
    isHasNewUserCoupon(item) {
      return this.isNewUserStyle && item.isNewUserFrame == '1' && item.couponDetailList?.length > 0
    },
    isNewUserSticky(showItems) {
      return this.isNewUserStyle && showItems.filter(item => item.isNewUserFrame != '1').length > 0
    },
    getDynamicStyle(newUserCoupon = false) {
      const GB_cssRight = this.context?.GB_cssRight
      const metaData = this.metaData ?? this.$attrs?.propData?.props?.metaData ?? {}
      const firstItem = this.items?.[0] ?? {}
      // 优先先取组件维度，再取坑位维度
      const textColor = metaData.showCustomBg == '1' ? (metaData.textColor || '#5C0B0B') : 
        firstItem?.showCustomBg == '1' ? firstItem?.textColor || '#5C0B0B' : '#5C0B0B'
      const bgColor = metaData.showCustomBg == '1' ? (metaData.bgColor || '#FCF5E6') : 
        firstItem?.showCustomBg == '1' ?  firstItem?.bgColor || '#FCF5E6' : '#FCF5E6'
      // single-free-3e9afaec82 单列
      // single-free-ar-aa9baefec1 单列ar
      // two-free-2b6aece2d7 双列
      // two-free-ar-71fcf1f882 双列ar
      let bg, border, borderRadius
      if(metaData.showCustomBg == '1') {
        // 背景图片
        bg = metaData.bgType == 2 ? `url(${ metaData?.bgImage?.src }) no-repeat` : `${ bgColor }`
        border = 'none'
      } else {
        // 纯色 + 蒙层
        if(this.isSticky && !GB_cssRight) {
          // 单行背景色 非ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/single-free-3e9afaec82.png') no-repeat top right / contain, ${bgColor} `
        } else if(!this.isSticky && !GB_cssRight){
          // 两列背景色 非ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/two-free-2b6aece2d7.png') no-repeat top right / contain, ${bgColor} `
        } else if(this.isSticky && GB_cssRight) {
          // 单行背景色 ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/single-free-ar-aa9baefec1.png') no-repeat top left / contain, ${bgColor} `
        } else if(!this.isSticky && GB_cssRight) {
          // 两列背景色 ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/two-free-ar-71fcf1f882.png') no-repeat top left / contain, ${bgColor} `
        }
        border = `0.4px solid ${hexToRGBA(textColor, 0.1)}`
      }
      if (this.metaData?.isNewUserStyle) { // 免邮
        bg = hexToRGBA(metaData.freeShippingColor, 0.07)
        border = 'none'
      }
      if (newUserCoupon) { // 券
        bg = hexToRGBA(metaData.couponFrameBgColor, 1)
        border = `0.5px solid ${hexToRGBA(metaData.couponFrameTitleColor, 0.1)}`
        if(metaData?.couponBackgroundImage?.src){
          bg = `url(${metaData.couponBackgroundImage.src}) no-repeat`
        }
        // 列表不需要描边
        if (this.pageFrom == 'list') {
          border = 'none'
          borderRadius = '0px'
        }
      }
      // 父元素设置背景色减少轮播闪烁的视觉差
      this.$emit('getContainerStyle', {
        backgroundColor: bg,
      })
      return {
        'background': bg,
        'border': border,
        'backgroundSize': 'cover',
        'height': newUserCoupon ? 'calc(100% - 1px)' : '',
        'borderRadius': borderRadius || '',
      }
    },
  },
})
</script>
