<template>
  <section
    class="free-shipping-coupon free-shipping-childType couponCountdown-type-6"
    :class="{
      'free-type-sticky': isSticky
    }"
    @click="handleCouponClick"
  >
    <template v-if="isNewSticky">
      <NewUserItem
        :item="item"
        :meta-data="metaData"
        :title-name="titleReal"
        :is-one-comp="isOneComp"
        :icon-name="iconClass"
        :main-title-color="titleColor"
      />
    </template>
    <template v-else>
      <!-- 两列右下角icon -->
      <div
        v-if="!isSticky"
        class="freeshipping-rb"
      >
        <div class="freeshipping-rb-icon not-fsp-element">
          <Icon
            :name="iconClass"
            :color="processBgColor"
            size="16px"
            :style="{height: '16px'}"
          />
        </div>
      </div>
      <!-- 单列左边icon -->
      <div 
        v-else
        class="sticky-single__icon-wrap"
      >
        <div class="sticky-single-flex not-fsp-element">
          <Icon
            :name="iconClass"
            :color="mainTitleColor"
            size="16px"
            class="free-shipping-coupon__title-icon"
            :style="{height: '16px'}"
          />
        </div>
      </div>
      <div 
        class="free-shipping-coupon__title coupondown-title"
      >
        <!-- 顶部文案 -->
        <p 
          class="free-shipping-coupon__title-txt"
          :class="{ 
            'is-sticky': isSticky,
          }"
          :style="{color: mainTitleColor, fontWeight: 'bold'}"
        >
          <!-- 吸顶 -->
          <template v-if="isSticky">
            <!-- 吸顶文案 -->
            <span
              :style="{color: subTitleColor, fontWeight: 'normal', fontSize: '10px', lineHeight: '13px'}"
            >{{ topTitle }}</span>
          </template>
          <!-- 不吸顶 -->
          <template v-else>
            {{ titleReal }}
          </template>
        </p>
      </div>
      <!-- 倒计时 -->
      <section
        v-if="showCountdown"
        class="free-shipping-coupon__sub-info countdown"
        :class="{ 'is-sticky': isSticky }"
        :style="{color: subTitleColor, fontSize: HomePageFontStyleNew ? '11px' : '10px'}"
      >
        <span
          :style="countdownTextStyle()"
        > {{ language.SHEIN_KEY_PWA_25713 }} </span>
        <div
          class="box"
          style="direction: ltr"
          tabindex="0"
          role="text"
        >
          <BaseCountdown
            v-if="countTimer"
            :count-timer="countTimer"
            :itemStyle="{
              color: metaData?.isNewUserStyle == '1' ? metaData.couponBgColorStart : (metaData.bgType == 2 && metaData?.bgImage?.src) ? '#fff' : bgColor,
              background: metaData?.isNewUserStyle == '1' ? hexToRGBA(subTitleColor, 0.8) : (metaData.bgType == 2 && metaData?.bgImage?.src) ? '#000000cc': hexToRGBA(textColor, 0.8)
            }"
          />
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import BaseCountdown from '../atomic-component/BaseCountdown'
import { CountDown, template } from '@shein/common-function'
import { getServerTime } from 'public/src/pages/components/ccc/common/utils.js'
import { Icon } from '@shein-aidc/icon-vue3'
import NewUserItem from '../atomic-component/NewUserItem'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

//mixin
import freeshippingMixin from '../mixin'
// 优惠券倒计时类型组件
export default defineComponent({
  name: 'CouponCountdown',
  components: {
    Icon,
    BaseCountdown,
    NewUserItem
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon', 'updateParentChange'],
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    couponCountdown: { // 用户所有的优惠券（根据条件排序过的）
      type: Array,
      default() {
        return []
      }
    },
    isHasFreeShippingType: { // 是否有免邮券类型的展示
      type: Boolean,
      default: false
    },
    isNewSticky: {
      type: Boolean,
      default: false
    },
    titleColor: {
      type: String,
      default: ''
    },
    isOneComp: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const  {
      isFullActivity = '0', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      coupon_type,
      apply_for,
      couponRules = [],
      showCountdown = 'false',

    } = this.item
    return {
      countTimer: new CountDown(),
      showCountdown: showCountdown == 'true', // 是否展示倒计时
      HomePageFontStyleNew: false,
      isFullActivity: isFullActivity == '1', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      coupon_type, // 1:满减,2:折扣,3:免邮
      apply_for, // 适用场景 1shopping cart;2product;3category;4vc;5shipping;6会员免邮卡;7选品
      couponRules
    }
  },
  computed: {
    // {diff} 取 diffPrice、{1} 取 priceSymbol
    topTitle() {
      const {  priceSymbol  } = this.couponRules[0] || {}
      const title =  this.isFullActivity ? template('', priceSymbol, this.topTitleFulfil) : template('', priceSymbol, this.topTitleNotFulfil)
      return title?.trim()
    },
    titleReal() {
      const {  priceSymbol  } = this.couponRules[0] || {}
      return template('', priceSymbol, this.title)
    },
    subTitleReal() {
      return  this.subtitle 
    },
    // 是折扣券
    isDisCount() {
      return this.coupon_type && this?.coupon_type !== '1'
    },
    // 是满减券
    isFulfill() {
      return this.coupon_type && this?.coupon_type == '1'
    },
    // 是免邮券 优先判断此类型
    isFreeShipping() {
      return this.apply_for == '5' || this.apply_for == '6'
    },
    iconClass() {
      let icon
      if(this.isFreeShipping){
        icon = 'sui_icon_policy_shipping_16px'
      } else if(this.isFulfill) {
        icon = 'sui_icon_policy_discount_16px'
      } else if(this.isDisCount) {
        icon = 'sui_icon_policy_discount_16px'
      }
      return `${icon}`
    },
    layoutSrc() {
      let src
      if(this.isFreeShipping){
        src = 'free-shipping-6a5f8b9d7e.png'
      } else if(this.isFulfill) {
        src = 'coupon-83cdd21719.png'
      } else if(this.isDisCount) {
        src = 'discount-83cdd21719.png'
      }
      return `/pwa_dist/images/ccc/freeshipping/${src}`
    }
  },
  mounted() {
    this.initCountDown()
  },
  methods: {
    template,
    hexToRGBA,
    // 倒计时结束处理的函数
    handelTimeEnd() {
      this.showCountdown = false
      // 检查下一张有倒计时的优惠券作为显示
      // 这个emit名可以做一些injectdata的变化或者showItems变化，反正就是通知给父组件去操作
      setTimeout(() => {
        this.$emit('updateParentChange', {
          item: this.item,
          index: this.index
        })
      }, 2000)
    },
    getCountTime(curTimeVal) {
      // startTime, endTime
      const curTime = curTimeVal / 1000
      const { startTime, couponEndTime: endTime  } = this.item
      // 1. 异常时间段
      if (curTime < Number(startTime) || Number(endTime / 1000) < curTime) {
        this.handelTimeEnd()
        return 0
      }
      this.showCountdown = true

      return Math.floor(endTime / 1000 - curTime)
    },
    async initCountDown() {
      const serverTime = this.isSticky ? Date.now() : await getServerTime()
      const countTime = this.getCountTime(serverTime)
      this.countTimer.start({
        seconds: countTime,
        dayMode: true,
        endFunc: this.handelTimeEnd
      })
    },
    handleCouponClick() {
      this.$emit('clickCoupon', {
        item: this.item,
        index: this.index,
        title: this.language.SHEIN_KEY_PWA_24744,
      })
    },
    countdownTextStyle() {
      const style =  {
        display: 'inline-block',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        verticalAlign: 'middle',
        marginRight: '1px',
        fontSize: this.HomePageFontStyleNew ? '11px' : '10px',
      }
      if (!this.isSticky) {
        style.lineHeight = 2
      }
      return style
    },
  },
})
</script>

<style lang="less" scoped>
.countdown {
  display: flex;
  z-index: 2;
  .box {
    display: inline-block;
    vertical-align: middle;
  }
}

.free-shipping-coupon {
  &__title-txt {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.is-sticky {
      height: 100%;
      align-items: center;
      display: flex;
      font-size: 10px;
      line-height: 12px;
      white-space: pre-wrap;
      margin-left: 0px !important; /* stylelint-disable-line declaration-no-important */
      &.had-progress-bar {
        padding-bottom: 3px;
      }
      > span {
        display: -webkit-box;
        -webkit-line-clamp:2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-height: 12px;
        width: 100%;
        // margin-top: -6px;
      }
    }
  }
  &__progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 20px);
    height: 3px;
    background-color: rgba(158, 100, 35, 0.13);
    border-radius: 1.5px;
    overflow: hidden;
  }
  &__progress-bar {
    position: absolute;
    height: 100%;
    background-color: #222222;
    width: 30%;
  }

}

.free-shipping-coupon__sub-info:not(.is-sticky) {
  max-width: calc(100% - 16px);
}

.free-type-sticky {
  line-height: 72/75rem;
  display: flex;
  .coupondown-title {
    width: 60%;
  }
  .free-shipping-coupon__sub-info {
    padding-top: 0;
    font-size: 10px;
  }
}

.countdown-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-right: 1px;
  font-size: 10px;
  line-height: 18px;
}
.countdown.is-sticky .countdown-text {
  line-height: 72/75rem;
}

.coupondown-title {
  display: flex;
  .free-shipping-coupon__title-txt {
    margin-left: 4px;
    font-size: 12px;
  }
}
</style>
