<template>
  <div 
    class="new-user-coupon not-fsp-element new-user-only-coupon__sticky"
    :style="getDynamicStyle()"
  >
    <component
      :is="component"
      v-for="(component, index) in components"
      ref="newUserStickyRef"
      :key="index"
      v-tap="getAnalysisData('2-22-2', { item: showItems[index], index, customComponentName, useBffApi: true })"
      v-expose="getAnalysisData('2-22-1', { item: showItems[index], index, customComponentName, useBffApi: true })"
      :is-new-sticky="true"
      :isNewUserStyle="true"
      :language="language"
      :title-color="mainTitleColor"
      :item="showItems[index]"
      :is-one-comp="components.length === 1"
      @click-coupon="clickCoupon"
      v-bind="componentAttrs"
    />
    <div 
      class="new-user-coupon__language"
      :style="languageStyle"
    >
      <span>
        {{ language?.SHEIN_KEY_PWA_30937 }}
      </span>
    </div>
  </div>
</template>
<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import FreeShipping from './FreeShipping.vue'
import CollectOrder from './CollectOrder.vue'
import ReturnCoupon from './ReturnCoupon.vue'
import SelfCoupon from './SelfCoupon.vue'
import CouponCountdown from './CouponCountdown.vue'
import freeshippingMixin from '../mixin'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

export default defineComponent({
  name: 'NewUserSticky',
  directives: {
    expose,
    tap,
  },
  components: {
    FreeShipping,
    CollectOrder,
    ReturnCoupon,
    SelfCoupon,
    CouponCountdown,
    BaseImg
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon'],
  inheritAttrs: true,
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    showItems: {
      type: Array,
      default() {
        return []
      }
    },
    onlySingleStylePage: {
      type: Boolean,
      default: false
    },
    getAnalysisData: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      components: [],
      titles: []
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    // 埋点， 吸顶自定义的组件名称
    customComponentName() {
      return 'policy_floating'
    },
    languageStyle() {
      if (this.onlySingleStylePage) {
        return {
          height: '72/75rem',
          color: this.metaData?.labelBgColorEnd,
        }
      }
      return {
        color: this.metaData?.labelBgColorEnd,
      }
    },
  },
  created() {
    this.analysisShowItems()
  },
  methods: {
    getDynamicStyle() {
      if(this.metaData?.freeShippingBackgroundImage?.src){
        return {
          background: `url(${this.metaData.freeShippingBackgroundImage.src})`,
          backgroundSize: 'cover',
        }
      }
      return {
        backgroundColor: this.metaData?.couponFrameBgColor || '#F5F5F5',
      }
    },
    // 根据showItems 前两个数据得到对应的组件
    analysisShowItems() {
      const end = this.showItems.length > 1 ? 2 : this.showItems.length
      const newItems = this.showItems.slice(0, end)?.filter(item => item.isNewUserFrame != '1') || []
      // type-组件路径 映射表
      const typeMap = {
        1: FreeShipping,
        2: CollectOrder,
        3: ReturnCoupon,
        4: SelfCoupon,
        5: SelfCoupon,
        6: CouponCountdown
      }
      // 循环遍历showItems，根据type值返回对应的组件
      this.components = newItems.map((item) => {
        return typeMap[item.type]
      })
    },
    handleCouponClick(item = {}, index) {
      this.$emit('clickCoupon', {
        item: {
          ...item,
        },
        index: this.index || index,
      })
    },
    // 点击事件上报，新客融合吸顶接收
    clickCoupon(item) {
      this.$emit('clickCoupon', item)
    }
  },
})
</script>
<style lang="less" scoped>
.new-user-coupon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  :deep(&__container) {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  :deep(&__shipping) {
    display: flex;  
    max-width: 2.64rem;
    align-items: center;
  }
  :deep(&__return) {
    display: flex;
    margin-right: .2667rem;
  }
  :deep(&__title) {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    margin-left: .1067rem;
    .text-overflow();
  }
  :deep(&__is-one) {
    max-width: 4.2rem;
  }
  :deep(&__language) {
    line-height: .8533rem;
    max-width: 50%;
    min-width: 35%;
    color: #FF3351;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .margin-r(.2667rem);
  }
  :deep(.free-shipping-coupon:nth-of-type(2)) {
    .padding-l(0);
  }
}

</style>
