<template>
  <section 
    class="free-shipping-coupon free-shipping-childType returnCoupon-type-3"
    :class="{
      'return-coupon-sticky': isSticky
    }"
    @click="handleCouponClick"
  >
    <template v-if="isNewSticky">
      <NewUserItem
        :item="item"
        :meta-data="metaData"
        :title-name="titleReal"
        :is-one-comp="isOneComp"
        icon-name="sui_icon_policy_discount_16px"
        :main-title-color="titleColor"
      />
    </template>
    <template v-else>
      <!-- 非吸顶-右下角icon -->
      <div
        v-if="!isSticky"
        class="freeshipping-rb"
      >
        <div class="freeshipping-rb-icon not-fsp-element">
          <Icon
            name="sui_icon_policy_discount_16px"
            :color="iconColor"
            size="16px"
          />
        </div>
      </div>
      <template v-if="!isSticky">
        <p
          class="free-shipping-coupon__title"
          :style="{color: mainTitleColor, fontWeight: 'bold'}"
        >
          {{ titleReal }}
        </p>
        <p
          class="free-shipping-coupon__sub-info"
          :style="{color: subTitleColor, fontSize: HomePageFontStyleNew ? '11px' : '10px'}"
        >
          {{ subTitleReal }}
        </p>
      </template>
      <!-- 吸顶 -->
      <div
        v-else
        class="free-shipping-coupon__title"
        :style="{color: subTitleColor, lineHeight: '13px'}"
      >
        <!-- threshold: 满足条件 -->
        <template v-if="isFullActivity">
          <Icon
            name="sui_icon_selected_16px"
            :color="freeThresholdIconColor"
            size="16px"
            class="free-shipping-coupon__title-icon color-success not-fsp-element"
            :style="{height: '16px'}"
          />
        </template>
        <div
          v-else
          class="sticky-single__icon-wrap"
        >
          <div class="sticky-single-flex not-fsp-element">
            <Icon
              name="sui_icon_policy_discount_16px"
              :color="mainTitleColor"
              size="16px"
              class="free-shipping-coupon__title-icon"
              :style="{height: '16px'}"
            />
          </div>
        </div>
        <!-- 文案 -->
        <span>
          {{ topTitle }}
        </span>
      </div>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import NewUserItem from '../atomic-component/NewUserItem'

//mixin
import freeshippingMixin from '../mixin'

// 订单返券
export default defineComponent({
  name: 'ReturnCoupon',
  components: {
    Icon,
    NewUserItem
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon'],
  inheritAttrs: true,
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    promotionInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    cartBriefInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    isNewSticky: {
      type: Boolean,
      default: false
    },
    titleColor: {
      type: String,
      default: ''
    },
    isOneComp: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const { 
      isFullActivity = '0', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      couponRules = [],
    } = this.item
    return {
      unStickyTitle: '',
      stickyText: '',
      info: {},
      titleVal: '', // 返回弹窗标题的金额数
      nonGetCartAmount: false, // 没有获取到购物车金额
      title,
      subtitle,
      topTitleFulfil,
      topTitleNotFulfil,
      isFullActivity: isFullActivity == '1', // 是否满足免邮
      couponRules,
      diffValue: couponRules?.[0]?.diffPrice || {},
    }
  },
  computed: {
    returnDrawerTitleVal() {
      if(this.isFullActivity) {
        // 满足条件的弹窗标题
        return this.language.SHEIN_KEY_PWA_24826
      } else {
        return template(`<span class='promo-color'>${this.diffPrice?.amountWithSymbol || '0'}</span>`, this.language.SHEIN_KEY_PWA_24747?.replace('{diff}', '{0}'))
      }
    },
    topTitle() {
      // {diff} 取 diffPrice、{1} 取 priceSymbol {0} 取 thresholdPrice
      const {  priceSymbol } = this.couponRules[0] || {}
      return this.isFullActivity ? template('', priceSymbol, this.topTitleFulfil) : template(this.diffPrice?.amountWithSymbol || '0', priceSymbol,  this.topTitleNotFulfil?.replace('{diff}', '{0}')) 
    },
    titleReal() {
      const {  priceSymbol  } = this.couponRules[0] || {}
      return template('', priceSymbol, this.title)
    },
    subTitleReal() {
      const { thresholdPrice } = this.couponRules[0] || {}
      return this.isFullActivity ?  this.subtitle :  template(thresholdPrice?.amountWithSymbol, this.subtitle) 
    },
    HomePageFontStyleNew() {
      return this.context?.abtInfoObj?.HomePageFontStyle == 'new' 
    },
    freeThresholdIconColor() { // 达到免邮门槛的icon颜色（对勾）
      const color = this.metaData?.isNewUserStyle === '1' ? (this.metaData?.newUserIconColor || '#169E00') : (this.metaData?.iconColor || '#198055')
      return color
    },
  },
  watch: {
    cartBriefInfo: {
      handler () {
        this.handelStickyType()
      }
    }
  },
  methods: {
    template,
    handelStickyType() {
      let diffFreeAmount = this.cartBriefInfo?.diff_free_amount   // 免邮差值
      const isFreeShipping = this.cartBriefInfo?.is_free_shipping   // 是否满足免邮
      this.isFullActivity = isFreeShipping == 1
      this.diffPrice = diffFreeAmount
    },
    handleCouponClick() {
      this.$emit('clickCoupon', {
        item: this.item,
        index: this.index,
        title: this.returnDrawerTitleVal,
      })
    },
  },
})
</script>

<style lang="less" scoped>
.free-shipping-coupon.return-coupon-sticky {
  .free-shipping-coupon__title {
    max-width: 100%;
    padding-bottom: 0px;
  }
}
.free-shipping-coupon__sub-info:not(.is-sticky) {
  max-width: calc(100% - 16px);
  font-size: 10px;
}
</style>
