<template>
  <div
    class="new-user-coupon__shipping"
    :class="{'new-user-coupon__is-one': isOneComp }"

    @click="handleCouponClick(item, index)"
  >
    <BaseImg
      v-if="item?.iconType != 2 && item?.image?.src && (item?.type == 4 || item?.type == 5)"
      class="new-user-coupon__customImg"
      :placeholder="{
        width: item?.image?.width,
        height: item?.image?.height,
      }"
      :ratio="item?.image?.ratio"
      img-design-width="26"
      :img-src="item?.image?.src"
      :alt="item?.hrefTitle"
      :first-screen="true"
    />
    <Icon
      v-else
      :name="iconName"
      :color="mainTitleColor"
      size="16px"
      :style="{height: '16px'}"
    />

    <span 
      class="new-user-coupon__title"
      :style="{color: mainTitleColor}"
    >
      {{ titleName }}
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'

export default defineComponent({
  name: 'NewUserItem',
  components: {
    Icon,
    BaseImg
  },
  emits: ['handleCouponClick'],
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    metaData: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    mainTitleColor: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    titleName: {
      type: String,
      default: ''
    },
    isOneComp: {
      type: Boolean,
      default: false
    },

  },
  methods: {
    handleCouponClick(item, index) {
      this.$emit('handleCouponClick', item, index)
    }
  },
})
</script>
<style lang="less">
.new-user-coupon__customImg.base-img {
  width: .3467rem;
  min-width: .3467rem;
  height: .3467rem;
}
</style>

