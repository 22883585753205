<template>
  <div
    class="base-countdown not-fsp-element" 
    tabindex="0"
    role="text"
  >
    <span
      v-if="showDay"
      class="base-countdown__digit-num digit-d-num"
      :style="itemStyle"
    >{{ timeObj.D }}</span>
    <i  
      v-if="showDay" 
      class="base-countdown__Dcolon"
      :style="{
        color: itemStyle.background || '#000'
      }"
    >D</i>
    <span
      class="base-countdown__digit-num"
      :style="itemStyle"
    >{{ timeObj.H }}</span>
    <i 
      class="base-countdown__colon" 
      :style="{
        color: itemStyle.background || '#000'
      }"
    >:</i>
    <span
      class="base-countdown__digit-num"
      :style="itemStyle"
    >{{ timeObj.M }}</span>
    <i 
      class="base-countdown__colon"
      :style="{
        color: itemStyle.background || '#000'
      }"
    >:</i>
    <span
      class="base-countdown__digit-num"
      :style="itemStyle"
    >{{ timeObj.S }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BaseCountdown',
  props: {
    countTimer: {
      type: Object,
      default: () => {
        return  {
          timeObj: {},
        }
      }
    },
    itemStyle: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    timeObj() {
      return this?.countTimer?.timeObj || {}
    },
    showDay() {
      return this.timeObj.D > 0
    },
  },
  mounted() {

  },
  methods: {

  },
})
</script>

<style lang="less" scoped>
.base-countdown {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'SF UI Text', "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  &__digit-num {
    background: #000;
    text-align: center;
    padding: 1px;
    color: #fff;
    width: 21px;
    height: 21px;
    line-height: 18px;
    transform: scale(0.67);
    transform-origin: center;
    display: inline-block;
    margin: 0 1px;
    border-radius: 2px;
  }
  &__colon {
    vertical-align: top;
    margin: 0 -4/75rem;
  }
  &__Dcolon {
    font-size: 10px;
    margin: 0 -4/75rem;
  }
  .digit-d-num {
    width: auto;
    min-width: 18px;
  }
}
</style>
