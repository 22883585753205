<template>
  <section
    v-tap="getAnalysisData('2-22-2', { item: couponItem, customParams, index: 0, customComponentName, useBffApi: true })"
    v-expose="getAnalysisData('2-22-1', { item: couponItem, customParams, index: 0, customComponentName, useBffApi: true })"
    class="new-user-coupon"
    @click="handleCouponClick"
  >
    <div
      v-if="!isSticky"
      class="free-shipping-coupon free-shipping-childType flex-new-user"
      :class="{
        'free-type-sticky': isSticky,
        'free-type-showCountdown': !showCountdown
      }"
    >
      <!-- 文案 -->
      <p 
        class="new-user-coupon__title-txt"
        :class="{ 
          'is-sticky': isSticky,
        }"
      >
        <!-- 不吸顶 -->
        <template
          v-if="!isSticky"
        >
          <span :style="{color: couponStyle?.couponFrameTitleColor , fontWeight: 'bold'}">{{ language.SHEIN_KEY_PWA_30936 }}</span>
        </template>
      </p>
      <!-- 倒计时 -->
      <div
        v-if="showCountdown && !isSticky"
        class="free-shipping-coupon__new-sub-info countdown"
        :class="{ 'is-sticky': isSticky }"
        :style="{color: couponStyle?.subTitleColor}"
      >
        <div
          class="countdown-text"
          :style="{color: couponStyle?.subTitleColor}"
        >
          {{ language.SHEIN_KEY_PWA_30973 }}
        </div>
        <div
          class="box cccCountTimeBox"
          style="direction: ltr"
          tabindex="0"
          role="text"
        >
          <BaseCountdown
            v-if="countTimer"
            :count-timer="countTimer"
            :itemStyle="{
              color: metaData?.isNewUserStyle == '1' ? metaData.couponBgColorStart : (metaData.bgType == 2 && metaData?.bgImage?.src) ? '#fff' : bgColor,
              background: metaData?.isNewUserStyle == '1' ? hexToRGBA(subTitleColor, 0.8) : (metaData.bgType == 2 && metaData?.bgImage?.src) ? '#000000cc': hexToRGBA(textColor, 0.8)
            }"
          />
        </div>
      </div>
      <div
        v-else
        class="countdown-text"
        :style="{color: couponStyle?.subTitleColor}"
      >
        {{ language.SHEIN_KEY_PWA_30972 }}
      </div>
    </div>
    <!-- 券 -->
    <div 
      v-if="!isSticky"
      class="new-user-coupon__content"
      :class="{ 'new-user-coupon__type-sticky': isSticky }"
    >
      <div
        v-for="(coupon, i) in newUserCoupon"
        :key="i"
        :style="newUserCoupon.length > 1 ? shortCouponStyle : longCouponStyle"
        class="new-user-coupon__item"
      >
        <div 
          class="new-user-coupon__ellipse new-user-coupon__left"
          :class="{ 'new-user-coupon__ar-left': GB_cssRight, [`new-user-coupon__left-${index}`]: true }"
          :style="ellipseStyle"
        ></div>
        <div
          ref="couponTitle"
          class="new-user-coupon__mainTitle"
        >
          {{ coupon.title }}
        </div>
        <div class="new-user-coupon__subtitle">
          {{ coupon.subtitle }}
        </div>
        <div 
          class="new-user-coupon__ellipse new-user-coupon__right"
          :class="{ 'new-user-coupon__ar-right': GB_cssRight, [`new-user-coupon__right-${index}`]: true}"
          :style="ellipseStyle"
        ></div>
      </div>
    </div>
    <!-- 吸顶新人文案 -->
    <div 
      v-else
      class="new-user-coupon__content"
      :class="{ 'new-user-coupon__type-sticky': isSticky }"
    > 
      <div
        v-for="(coupon, i) in newUserCoupon"
        :key="i"
        :style="newUserCoupon.length > 1 ? shortCouponStyle : longCouponStyle"
        class="new-user-coupon__item new-user-coupon__sticky"
      >
        <div 
          class="new-user-coupon__ellipse new-user-coupon__left"
          :class="{ 'new-user-coupon__ar-left': GB_cssRight, [`new-user-coupon__left-${index}`]: true }"
          :style="ellipseStyle"
        ></div>
        <div class="new-user-coupon__sticky-subtitle">
          <span class="new-user-coupon__sticky-price">{{ coupon.title }}</span>
          <span>{{ coupon.subtitle }}</span>
        </div>
        <div 
          class="new-user-coupon__ellipse new-user-coupon__right"
          :class="{ 'new-user-coupon__ar-right': GB_cssRight, [`new-user-coupon__right-${index}`]: true }"
          :style="ellipseStyle"
        ></div>
      </div>
    </div>
    <div 
      v-if="isSticky" 
      class="new-user-coupon__sticky-text"
      :style="{color: metaData?.labelBgColorEnd	}"
    >
      <span>
        {{ language?.SHEIN_KEY_PWA_30937 }}
      </span>
    </div>
  </section>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { template, CountDown } from '@shein/common-function'
import BaseCountdown from '../atomic-component/BaseCountdown.vue'
//mixin
import freeshippingMixin from '../mixin.js'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
const { GB_cssRight } = typeof window !== 'undefined' ? gbCommonInfo : {}

export default defineComponent({
  name: 'NewUserCoupon',
  directives: {
    expose,
    tap,
  },
  components: {
    BaseCountdown,
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon'],
  inheritAttrs: true,
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    promotionInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    cartBriefInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    getAnalysisData: {
      type: Function,
      default() {
        return () => {}
      }
    },
  },
  data() {
    const { 
      showCountdown = 'false',
      couponDetailList,
    } = this.item
    return {
      // 当展示的两张券中，若较快过期的券有效期<72小时，展示倒计时（取较快过期券的有效期）
      showCountdown: showCountdown == 'true', // 是否展示倒计时
      countTimer: new CountDown(),
      GB_cssRight,
      couponDetailList
    }
  },
  computed: {
    // 券信息
    newUserCoupon() {
      const newUserCouponInfo = this.couponDetailList
      if (this.couponDetailList.length > 1) {
        return newUserCouponInfo.slice(0, 2)
      } else if (newUserCouponInfo.length == 1) {
        return newUserCouponInfo
      }
      return []
    },
    // 埋点数据
    customParams() {
      // 拼接券信息
      return {
        coupon_code: this.newUserCoupon.map(it => it.couponCode).join('|')
      }
    },
    couponItem() {
      return {
        ...this.metaData,
        ...this.item,
        type: 'newUserCoupon',
        item_loc: 3
      }
    },
    // 埋点， 吸顶自定义的组件名称
    customComponentName() {
      if(this.isSticky) {
        return 'policy_floating'
      }
      return ''
    },
    couponStyle () {
      const { 
        freeShippingColor = '', // 免邮文案色
        subTitleColor = '', // 副标题色
        labelBgColorStart = '', // 标签背景起始色
        labelBgColorEnd = '', // 标签背景结束色
        couponBgColorEnd = '', // 优惠券背景起始色
        couponBgColorStart = '', // 优惠券背景结束色
        couponFrameBgColor = '', // 券信息帧背景色
        couponFrameTitleColor = '', // 券信息帧主标题色
        couponTextColor = '', // 优惠券面文案色
      } = this.metaData
      return {
        couponBgColorEnd,
        couponBgColorStart,
        couponFrameBgColor,
        couponFrameTitleColor,
        couponTextColor,
        freeShippingColor,
        labelBgColorEnd,
        labelBgColorStart,
        subTitleColor
      }
    },
    ellipseStyle() {
      return {
        border: `0.5px solid ${hexToRGBA(this.couponStyle?.couponFrameTitleColor, 0.2)}`
      }
    },
    couponBg() {
      // 定义渐变的方向
      let direction = '90deg'
      if (GB_cssRight) {
        direction = '270deg'
      }
      return {
        background: `linear-gradient(${direction}, ${this.couponStyle?.couponBgColorStart} 0%, ${this.couponStyle?.couponBgColorEnd} 66%, ${this.couponStyle?.couponBgColorStart})`,
        color: this.couponStyle?.couponTextColor,
        border: `0.5px solid ${hexToRGBA(this.couponStyle?.couponFrameTitleColor, 0.2)}`
      }
    },
    longCouponStyle() {
      if (this.isSticky) {
        return {
          ...this.couponBg,
          height: '0.64rem',
        }
      }
      return {
        ...this.couponBg,
        width: '4.72rem',
        height: '1.0667rem',
      }
    },
    shortCouponStyle() {
      if (this.isSticky) {
        return {
          ...this.couponBg,
          height: '0.64rem',
          width: '2.72rem'
        }
      }
      return {
        ...this.couponBg,
        width: '2.72rem',
        height: '1.0667rem'
      }
    },
    
  },
  activated() {
    this.initCountDown()
  },
  mounted() {
    this.initCountDown()
    this.initTitleFontSize()
  },
  methods: {
    template,
    hexToRGBA,
    // 初始化标题 font-size
    initTitleFontSize() {
      const refTitle = this.$refs.couponTitle
      if (!refTitle) return
      refTitle.forEach((item) => {
        this.isOverflowing(item)
      })
    },
    // 判断文字是否超出父容器宽度
    isOverflowing(el) {
      // 拿到兄弟元素
      const nextSibling = el?.nextElementSibling
      if (!el || !nextSibling) return
      const minFontSize = 9
      const minSubFontSize = 8
      const isOverflowing = el?.scrollWidth >= el?.parentNode?.clientWidth - 8 * 2 // 减去两边的margin
      if (!isOverflowing) return
      // 缩放字体
      el.style.fontSize = minFontSize + 'px'
      nextSibling.style.fontSize = minSubFontSize + 'px'
    },
    calOverPrice(coupon) {
      // 计算满减券的门槛
      const { couponRule, couponTypeId } = coupon
      // 后端会排序，取第一个即可
      const couponResult = couponRule?.[0]
      return {
        overPrice: couponResult.min.priceSymbol,
        couponValue: couponTypeId == '1' ? couponResult.price.priceSymbol : (couponResult.price.price + '%')
      }
    },
    // 倒计时结束处理的函数
    handelTimeEnd() {
      this.showCountdown = false
    },
    getCountTime(curTime) {
      if (!this.showCountdown) return
      const endTime = this.newUserCoupon[0]?.couponEndTime  // 时间戳转换
      // 1. 结束时间 - 当前时间 > 72h
      if ( endTime - curTime > 72 * 3600) {
        this.handelTimeEnd()
      }
      // const hours_24 = 24 * 3600
      const timeLag = (endTime - curTime) // 结束时间 - 当前时间 （单位s）
      return timeLag
    },
    async initCountDown() {
      const serverTime =  Math.floor(new Date().getTime() / 1000) 
      const countTime = this.getCountTime(serverTime) 
      this.countTimer.start({
        seconds: countTime,
        dayMode: false,
        endFunc: this.handelTimeEnd
      })
    },
    handleCouponClick() {
      this.$emit('clickCoupon', {
        item: {
          ...this.item,
          ...this.metaData,
          type: 'newUserCoupon',
        },
        index: this.index,
      })
    },
  },
})
</script>

<style lang="less" scoped>
.new-user-coupon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  &__content {
    display: flex;
    align-items: center;
    padding: 0 .2667rem;
  }
  &__type-sticky{
    height: .8533rem;
  }
  &__item {
    position: relative;
    margin-right: .1067rem;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    // 券阴影
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .1);
    mask: radial-gradient(circle 10px at 0% 50%, transparent 50%, black 50%) left,
    radial-gradient(circle 10px at 100% 50%, transparent 50%, black 50%) right;
    mask-composite: intersect;
  }
  &__is-sticky {
    filter: drop-shadow(0px 2px 2px rgba(94, 43, 14, 0.08));
  }
  &__mainTitle {
    background-clip: text;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 14px */
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 3px;
    margin-left: 8px;
    margin-right: 8px;
  }
  &__subtitle {
    width: 86px;
    margin: 0 8px;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 10px */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__ellipse {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
  }
  &__left {
    left: -6px;
    clip-path: circle(67.5% at 100% 50%);
  }
  &__right {
    right: -6px;
    clip-path: circle(67.5% at 0% 50%);
  }
  &__ar-left {
    left: -6px;
    clip-path: circle(67.5% at 0% 50%);
  }
  &__ar-right {
    right: -6px;
    clip-path: circle(67.5% at 100% 50%);
  }
  &__sticky-subtitle{
    width: 90%;
    max-width: 5rem;
    color: #5E2B0E;
    font-size: 11px;
    font-style: normal;
    // font-weight: 800;
    line-height: normal;
    margin: 0 .4267rem;
    text-align: center;
    .text-overflow();
  }
  &__sticky-price {
    display: inline-block;
    width: max-content;
    margin-right: .08rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 14px */;
  }
  &__sticky-text {
    color: #FF3351;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: .2667rem;
    .text-overflow();
    max-width: 3.54rem;
    min-width: 3rem;
  }
}
.countdown {
  display: flex;
  z-index: 2;
  .box {
    display: inline-block;
    vertical-align: middle;
  }
}

.new-user-coupon {
  &__title {
    &.full-width {
      max-width: 100% !important; /* stylelint-disable-line declaration-no-important */
      flex: 1;
    }
  }
  &__title-txt {
    width: 3.12rem; 
    .text-overflow();
    &.is-sticky {
      height: 100%;
      align-items: center;
      display: flex;
      font-size: 11px;
      line-height: 12px;
      flex: 1;
      > span {
        display: -webkit-box;
        -webkit-line-clamp:2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-height: 13px;
      }
    }
  }
}
.flex-new-user {
  max-width: 3.68rem;
}
.free-shipping-coupon__new-sub-info {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.free-shipping-coupon__new-sub-info:not(.is-sticky) {
    font-size: 10px;
  }

.free-type-sticky {
  line-height: 72/75rem;
  .free-shipping-coupon__new-sub-info {
    padding-top: 0;
  }
}

.countdown-text {
  display: inline-block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-right: 1px;
  font-size: 10px;
  line-height: 2;
  //line-height: 18px;
}
.countdown.is-sticky .countdown-text {
  line-height: 72/75rem;
}

.bottomProgressWrap {
  width: calc(100% - 20px);
  position: absolute;
  bottom: 2px;
}
.free-type-sticky .bottomProgressWrap {
  width: calc(100% - 18px);
  margin-left: 2px;
}
.free-type-sticky.free-type-showCountdown .bottomProgressWrap {
  width: 100%;
}
.free-type-sticky .cccCountTimeBox {
  margin-right: -2px;
}

</style>
