<template>
  <section
    v-if="couponRules.length"
    class="free-shipping-coupon free-shipping-childType collectOrder-type-2"
    @click="handleCouponClick"
  >
    <template v-if="isNewSticky">
      <NewUserItem
        :item="item"
        :meta-data="metaData"
        :title-name="titleReal"
        :is-one-comp="isOneComp"
        icon-name="sui_icon_policy_discount_16px"
        :main-title-color="titleColor"
      />
    </template>
    <template v-else>
      <!-- 非吸顶-右下角icon -->
      <div
        v-if="!isSticky"
        class="freeshipping-rb"
      >
        <div class="freeshipping-rb-icon not-fsp-element">
          <Icon
            name="sui_icon_policy_discount_16px"
            :color="mainTitleColor"
            size="16px"
          />
        </div>
      </div>
      <!-- 吸顶 -->
      <template v-if="isSticky">
        <div
          class="free-shipping-coupon__title full-width"
          :style="{color: subTitleColor, lineHeight: '13px'}"
        >
          <div 
            class="sticky-single__icon-wrap"
          >
            <div class="sticky-single-flex">
              <div class="free-shipping-coupon__title-icon not-fsp-element">
                <Icon
                  name="sui_icon_policy_discount_16px"
                  :color="mainTitleColor"
                  size="16px"
                  :style="{height: '16px'}"
                />
              </div>
            </div>
          </div>
          <span>
            {{ topTitle }}
          </span>
        </div>
      </template>
      <!-- 不吸顶 -->
      <template v-else>
        <!-- 主标题是优惠券力度 -->
        <p
          class="free-shipping-coupon__title"
          :style="{color: mainTitleColor, fontWeight: 'bold'}"
        >
          <span>
            {{ titleReal }}
          </span>
        </p>
        <!-- 副标题门槛 -->
        <p
          class="free-shipping-coupon__sub-info"
          :style="{color: subTitleColor, fontSize: HomePageFontStyleNew ? '11px' : '10px'}"
        >
          {{ subTitleReal }}
        </p>
      </template>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import NewUserItem from '../atomic-component/NewUserItem'

//mixin
import freeshippingMixin from '../mixin'
// 优惠券凑单组件（优惠券信息展示）
export default defineComponent({
  name: 'CollectOrder',
  components: {
    Icon,
    NewUserItem
  },
  mixins: [freeshippingMixin],
  emits: ['clickCoupon'],
  inheritAttrs: true,
  props: {
    isSticky: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    couponInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    isNewSticky: {
      type: Boolean,
      default: false
    },
    titleColor: {
      type: String,
      default: ''
    },
    isOneComp: {
      type: Boolean,
      default: false
    },
  },
  data() {
    // const couponType = this.couponInfo?.couponTypeId || 0
    // const couponRules = this.couponInfo?.rule || []
    const { 
      isFullActivity = '0', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      couponRules = [],
    } = this.item
    return {
      // couponType,
      // couponRules,
      titleVal: '',
      // HomePageFontStyleNew: false,
      isFullActivity: isFullActivity == '1', // 是否满足免邮
      topTitleFulfil, // 吸顶时满足免邮的文案
      topTitleNotFulfil, // 吸顶时未满足免邮的文案
      title,  // 不吸顶标题
      subtitle, // 不吸顶副标题
      couponRules
    }
  },
  computed: {
    returnDrawerTitleVal() {  // 供弹窗标题使用
      const {  thresholdPrice } = this.couponRules[0] || {}
      if(!this.isFullActivity) {
        // 有门槛
        return template(`<span class='promo-color'>${thresholdPrice?.amountWithSymbol}</span>`, this.language.SHEIN_KEY_PWA_24828)
      } else {
        // 无门槛
        return template(this.language.SHEIN_KEY_PWA_24830)
      }
    },
    showCouponRule() {
      return this.couponRules[0] || {}
    },
    HomePageFontStyleNew() {
      return this.context?.abtInfoObj?.HomePageFontStyle == 'new'
    },
    titleReal() {
      const {  priceSymbol  } = this.couponRules[0] || {}
      return this.isFullActivity ? this.title : template('', priceSymbol, this.title)
    },
    topTitle() {
      // {diff} 取 diffPrice、{1} 取 priceSymbol
      const {  priceSymbol, thresholdPrice = {} } = this.couponRules[0] || {}
      return this.isFullActivity ? template(priceSymbol, this.topTitleFulfil) : template(thresholdPrice?.amountWithSymbol || '0', priceSymbol,  this.topTitleNotFulfil) 
    },
    subTitleReal() {
      const { thresholdPrice } = this.couponRules[0] || {}
      return this.isFullActivity ?  this.subtitle :  template(thresholdPrice?.amountWithSymbol, this.subtitle) 
    },
  },
  methods: {
    template,
    handleCouponClick() {
      this.$emit('clickCoupon', {
        item: this.item,
        index: this.index,
        title: this.returnDrawerTitleVal,
      })
    },
    initCouponInfo() {
      this.couponType = this.couponInfo?.couponTypeId
      this.couponRules = this.couponInfo?.rule || []
    },
    couponIsSetThreshold(couponItem) {
      const thresholdValue = couponItem?.min?.amount || 0
      return thresholdValue != 0 && thresholdValue != '0.01'
    },
    getCouponDiscountVal(couponItem) {
      if (!couponItem) return 0
      
      if (this.couponType == 1) {
        // 满减券
        return couponItem.value.amountWithSymbol
      } else {
        const { GB_cssRight } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
        // 2.5 
        // 折扣券
        return GB_cssRight ? `%${parseInt(couponItem.value.amount || 0)}` : `${parseInt(couponItem.value.amount || 0)}%`
      }
    },
  },
})
</script>

<style lang="less" scoped>
.free-shipping-coupon {
  &__title {
    &.full-width {
      max-width: 100% !important; /* stylelint-disable-line declaration-no-important */
      flex: 1;
    }
  }
  &__sub-info:not(.is-sticky) {
    max-width: calc(100% - 16px);
    font-size: 10px;
  }
  &__swiper-container {
    height: 1.1733rem;
    overflow: hidden;
    margin: unset;
    // .swiper-slide {
    //   height: 100% !important;
    // }
    .free-shipping-coupon__sub-info {
      // height: 100%;
      height: 0.48rem;
    }
  }
}
</style>
